import { deviceTypes } from "constants/deviceTypes"
import { accessAllowed } from "utils/auth"
import { privileges } from "constants/auth"
import NodeTypeSelector from "../components/InputFields/NodeTypeSelector"
import FlagsSelector from "../components/InputFields/FlagsSelector"

const refactoredOptions = [
	{
		privilege: privileges.CONTROL_CX,
		category: "Master Time",
		label: "Time - Get",
		value: "time-get",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "Master Time",
		label: "Time - Sync",
		value: "time-sync",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "IoT Client",
		label: "Restart IoT Client",
		value: "restart-iot",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Tracking",
		label: "Tracking - Enable",
		value: "enable-tracking",
		params: {},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Tracking",
		label: "Tracking - Disable",
		value: "disable-tracking",
		params: {},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.GAMECHANGE,
		category: "Tracking",
		label: "Send Node Parameters",
		value: "send-node-params-batch",
		params: {},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.GAMECHANGE,
		category: "Tracking",
		label: "Send Master Configuration",
		value: "send-master-params-batch",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.GAMECHANGE,
		category: "Tracking",
		label: "Call & Response Round to Start Using Broadcast",
		value: "broadcast-call-and-response",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["roundNumber"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Safety Stow - Restore",
		value: "restore-override",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Safety Stow - High Priority",
		value: "high-priority-keep-angle",
		params: {},
		nodeControl: true,
		paramSelectDependencies: ["inputAngle"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Safety Stow - Strongest Angle",
		value: "strong-angle-stow",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Safety Stow - Snow",
		value: "trigger-snow-safe-stow",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Clear Snow Stow",
		value: "clear-snow-stow",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Manual Restore",
		value: "low-priority-restore",
		params: {},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Manual Stow - 45 West",
		value: "low-priority-keep-angle",
		params: {
			angle: 45,
		},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Manual Stow - 32 West",
		value: "low-priority-keep-angle",
		params: {
			angle: 32,
		},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Manual Stow - 25 West",
		value: "low-priority-keep-angle",
		params: {
			angle: 25,
		},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Manual Stow - 5 West",
		value: "low-priority-keep-angle",
		params: {
			angle: 5,
		},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Manual Stow - 0 West",
		value: "low-priority-keep-angle",
		params: {
			angle: 0,
		},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Manual Stow - 45 East",
		value: "low-priority-keep-angle",
		params: {
			angle: -45,
		},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Manual Stow - Custom Angle",
		value: "low-priority-keep-angle",
		params: {},
		nodeControl: true,
		paramSelectDependencies: ["inputAngle"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Individual Node Stow",
		value: "low-priority-keep-angle",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["nId", "inputAngle"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Individual Node Restore",
		value: "low-priority-restore",
		params: {},
		nodeControl: true,
		paramSelectDependencies: ["nId"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Clear WeatherSmart",
		value: "disable-weathersmart",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Set WeatherSmart Envelope",
		value: "set-weathersmart-envelope",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["weathersmartEnvelopeWestLimit", "weathersmartEnvelopeEastLimit"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Enter HailStow",
		value: "activate-hailstow",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Clear HailStow",
		value: "deactivate-hailstow",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "AutoSmart Control",
		label: "AutoSmart - Enable",
		value: "enable-autosmart",
		params: {
			mode: 0x7859,
		},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Zigbee Network",
		label: "Master - Zigbee - Enable",
		value: "master-zigbee-enable",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Zigbee Network",
		label: "Master - Zigbee - Disable",
		value: "master-zigbee-disable",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Zigbee Network",
		label: "Node - Zigbee - Enable",
		value: "node-zigbee-enable",
		params: {},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Zigbee Network",
		label: "Node - Zigbee - Disable",
		value: "node-zigbee-disable",
		params: {},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "AutoSmart Control",
		label: "AutoSmart - Disable",
		value: "enable-autosmart",
		params: {
			mode: 0,
		},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Reset Stow Coordinator",
		value: "reset-stow-coordinator",
		params: {},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Enter Cleaning Mode",
		value: "enter-cleaning-mode",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["inputAngle"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Manual Stow Control",
		label: "Exit Cleaning Mode",
		value: "exit-cleaning-mode",
		params: {},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Alarms",
		label: "Reset Failed to Move Flags",
		value: "reset-flags",
		params: {
			flagBits: 257,
		},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Alarms",
		label: "Reset Flags",
		value: "reset-flags",
		nodeControl: true,
		paramSelectDependencies: ["flagBits"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Alarms",
		label: "Reset All Node Alarms",
		value: "reset-alarms",
		params: {
			alarmBits1: 57342,
			alarmBits2: 65534,
		},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Data",
		label: "Save Node Data",
		value: "save-node-data",
		params: {
			nId: 0,
			paramId: 45,
			param1: 27,
			param2: 5,
			param3: 18000,
			param4: 8,
			param5: 10,
			param6: 1,
		},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Data",
		label: "Start Call & Response Round",
		value: "start-arrr",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Data",
		label: "Realtime Call & Response Round",
		value: "realtime-call-and-response",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Data",
		label: "Fetch Master Data",
		value: "dt-master",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Data",
		label: "Fetch Nodes Data",
		value: "dt-nodes",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Data",
		label: "Disable sleep control",
		value: "disable-sleep-control",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Data",
		label: "Display Master Info",
		value: "display-master-info",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Data",
		label: "Read Node Params from Node",
		value: "read-node-params-from-node",
		params: {},
		nodeControl: true,
		paramSelectDependencies: ["nId"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Wind Sensor",
		label: "Enable Wind Sensor",
		value: "enable-wind-sensor",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Wind Sensor",
		label: "Disable Wind Sensor (24 hr)",
		value: "disable-wind-sensor",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Wind Sensor",
		label: "Send Server Wind Speed",
		value: "send-server-wind-speed",
		nodeControl: false,
		paramSelectDependencies: ["windSpeed"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Wind Sensor",
		label: "Send Server Wind Speed Valid Duration",
		value: "send-server-wind-speed-valid-duration",
		nodeControl: false,
		paramSelectDependencies: ["duration"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Depth Sensor",
		label: "Calibrate Depth Sensor",
		value: "calibrate-snow-baseline",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Depth Sensor",
		label: "Enable Depth Sensor",
		value: "enable-snow-stow",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Depth Sensor",
		label: "Disable Depth Sensor",
		value: "disable-snow-stow",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "Restart",
		label: "Restart Master",
		value: "soft-reset",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "Restart",
		label: "Restart All Nodes",
		value: "reset-all-nodes",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "Restart Node",
		label: "Restart Node",
		value: "reset-all-nodes",
		params: {},
		nodeControl: true,
		paramSelectDependencies: ["nId"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Realtime Data",
		label: "Node Data",
		value: "request-node-realtime-status",
		params: {},
		nodeControl: true,
		paramSelectDependencies: ["nId"],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "Realtime Data",
		label: "Wind Instant",
		value: "wind-instant",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "Realtime Data",
		label: "Depth Instant",
		value: "depth-instant",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Commissioning",
		label: "Enable Node Rebroadcast",
		value: "ARRR-enable-all-nodes-rebroadcast",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Commissioning",
		label: "Sync Node IDs",
		value: "sync-nodeids",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Commissioning",
		label: "Enable Node Rebroadcast with New Config",
		value: "ARRR-enable-nodes-rebroadcast-w-panId-channel",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["panId", "channel"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Commissioning",
		label: "Start All-At-Once",
		value: "all-at-once",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["panId", "channel"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Commissioning",
		label: "Reset Node Coordinates",
		value: "reset-coordinates",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Commissioning",
		label: "Set Backtracking Angle Tolerance",
		value: "set-backtracking-angle-tolerance",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["eastTolerance", "westTolerance"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Replacement",
		label: "Replace Actuator/Charging Panel",
		value: "post-replacement-reset-batch",
		params: {
			alarmBits1: 57342,
			alarmBits2: 65534,
			flagBits: 65535,
		},
		nodeControl: true,
		paramSelectDependencies: ["nId"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Replacement",
		label: "Replace Node",
		value: "node-replacement-batch",
		params: {},
		nodeControl: true,
		paramSelectDependencies: ["nId"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Rescue",
		label: "Rescue Node",
		value: "rescue-node",
		params: {},
		nodeControl: true,
		paramSelectDependencies: ["rescuerNId", "rescueeNId", "panId", "channel"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Rescue",
		label: "Auto Rescue Nodes",
		value: "auto-rescue-nodes",
		params: {
			nId1: 0,
			nId2: 0,
			nId3: 0,
			nId4: 0,
			nId5: 0,
			nId6: 0,
			nId7: 0,
			nId8: 0,
		},
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Testing & Troubleshooting",
		label: "Check Movement West",
		value: "high-priority-keep-angle",
		params: {
			angle: 45,
		},
		nodeControl: true,
		paramSelectDependencies: ["nId"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Testing & Troubleshooting",
		label: "Check Movement East",
		value: "high-priority-keep-angle",
		params: {
			angle: -45,
		},
		nodeControl: true,
		paramSelectDependencies: ["nId"],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Testing & Troubleshooting",
		label: "Refresh Device ID",
		value: "refresh-device-id",
		nodeControl: false,
		params: {},
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "OTA Realtime Mode",
		label: "Set OTA realtime conditions",
		value: "set-ota-realtime-conditions",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["allowOlderVersion"],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "OTA Realtime Mode",
		label: "Start OTA update using realtime call and respond",
		value: "start-ota-realtime-conditions",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["autoRebroadcast", "autoUnicast", "otaUpdateType"],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "OTA Auto Commissioning Mode",
		label: "Set OTA commissioning conditions",
		value: "set-ota-commissioning-conditions",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "OTA Auto Commissioning Mode",
		label: "Start OTA commissioning update",
		value: "start-ota-commissioning-update",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["autoRebroadcast", "autoUnicast"],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "OTA Overnight Scheduling Mode",
		label: "Set overnight OTA conditions",
		value: "set-overnight-ota-conditions",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["allowOlderVersion"],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "OTA Overnight Scheduling Mode",
		label: "Schedule overnight auto OTA",
		value: "schedule-overnight-auto-ota",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["autoRebroadcast", "autoUnicast", "otaUpdateType"],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "OTA Overnight Scheduling Mode",
		label: "Cancel scheduled overnight OTA",
		value: "cancel-scheduled-overnight-ota",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "OTA Auto-Nightly Scheduling",
		label: "Schedule Auto Nightly OTA Check",
		value: "schedule-nightly-ota-check",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["autoRebroadcast", "autoUnicast", "allowOlderVersion"],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "OTA Auto-Nightly Scheduling",
		label: "Cancel Auto Nightly OTA Check",
		value: "cancel-scheduled-nightly-ota-check",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "OTA Unicast Node",
		label: "Set OTA Unicast Conditions",
		value: "set-ota-unicast-conditions",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "OTA Unicast Node",
		label: "Start Unicast OTA Update",
		value: "start-unicast-ota-update",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["nId", "autoUnicast", "otaUpdateType"],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "Stop Current OTA and Resume Normal Operation",
		label: "Resume System Normal Operations",
		value: "resume-normal-ota-operation",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "IoT Client",
		label: "IoT Client Version",
		value: "client-version",
		params: {},
		nodeControl: false,
		paramSelectDependencies: [],
	},
	{
		privilege: privileges.CONTROL_STANDARD,
		category: "Maintenance",
		label: "HailStow Resume Tracking Timer",
		value: "hailstow-tracking-time",
		params: {},
		nodeControl: false,
		paramSelectDependencies: ["hailStowTrackingMinutes"],
	},
	{
		privilege: privileges.CONTROL_CX,
		category: "Maintenance",
		label: "Reset Node Temperature Calibration",
		value: "reset-node-temperature-calibration",
		nodeControl: true,
		paramSelectDependencies: [],
	},
	{
		value: "set-node-charger-control-param",
		label: "Set Node Charger Control Parameters",
		category: "Maintenance",
		privilege: privileges.CONTROL_CX,
		nodeControl: false,
		paramSelectDependencies: ["daysBetweenConditioning", "startHour", "minutes", "enable"],
	},
	//TODO: This can call the generic update-param instead
	{
		value: "set-disable-charger-while-moving",
		label: "Set Disable Charger While Moving",
		category: "Maintenance",
		privilege: privileges.CONTROL_CX,
		nodeControl: true,
		paramSelectDependencies: ["disable"],
	},
	{
		value: "update-param",
		label: "Set State of Charge Function",
		category: "Maintenance",
		privilege: privileges.GAMECHANGE,
		params: {
			paramString: "BATT_STOW_MODE",
			param1: 0,
			param2: 0,
			param4: 0,
			param5: 0,
			param6: 0,
		},
		paramSelectDependencies: ["enable_battery_stow_mode", "battery_voltage_offset", "stow_temperature_hysteresis"],
	},
	{
		value: "set-battery-maintenance-voltage-threshold",
		label: "Battery Maintenance Threshold",
		category: "Maintenance",
		nodeControl: true,
		privilege: privileges.GAMECHANGE,
		paramSelectDependencies: ["batteryVoltageThreshold"],
	},
	{
		value: "send-enable-power-boost",
		label: "Enable PowerBoost",
		category: "PowerBoost",
		privilege: privileges.GAMECHANGE,
		paramSelectDependencies: [],
	},
	{
		value: "send-disable-power-boost",
		label: "Disable PowerBoost",
		category: "PowerBoost",
		privilege: privileges.GAMECHANGE,
		paramSelectDependencies: [],
	},
	{
		value: "reset-node-height-offsets",
		label: "Reset Node Height Offsets",
		category: "PowerBoost",
		privilege: privileges.GAMECHANGE,
		paramSelectDependencies: [],
	},
	{
		value: "send-row-spacing-and-panel-length",
		label: "Send Row Spacing and Panel Length",
		category: "PowerBoost",
		privilege: privileges.GAMECHANGE,
		paramSelectDependencies: [],
	},
	{
		value: "set-charging-panel-length",
		label: "Set Charging Panel Length",
		category: "PowerBoost",
		privilege: privileges.GAMECHANGE,
		paramSelectDependencies: ["chargingPanelLength"],
	},
	{
		value: "set-layout-row-spacing",
		label: "Set Layout Row Spacing",
		category: "PowerBoost",
		privilege: privileges.GAMECHANGE,
		paramSelectDependencies: ["rowSpacing"],
	},
	{
		value: "set-power-boost-angle-tolerance",
		label: "Set PowerBoost Angle Tolerance",
		category: "PowerBoost",
		privilege: privileges.GAMECHANGE,
		paramSelectDependencies: ["chargingEastTolerance", "chargingWestTolerance"],
	},
	{
		value: "set-power-boost-send-height-conditions",
		label: "Set PowerBoost Send Height Conditions",
		category: "PowerBoost",
		privilege: privileges.GAMECHANGE,
		paramSelectDependencies: [],
	},
	{
		value: "send-node-height-offset",
		label: "Send Node Height Offset",
		category: "PowerBoost",
		helperText: "Set PowerBoost Send Height Conditions command must be sent before this command would take effect",
		privilege: privileges.GAMECHANGE,
		paramSelectDependencies: [],
	},
]

function validateHex(minLength, maxLength) {
	return (value) => {
		// noinspection JSCheckFunctionSignatures
		if (isNaN(value) && isNaN(`0x${value}`)) return false

		try {
			parseHex(value)
		} catch {
			return false
		}

		if (minLength && value.length < minLength) return false
		return !(maxLength && value.length > maxLength)
	}
}

function parseHex(value) {
	return parseInt(value, 16)
}

// TODO: Refactor this file and make commands declare these directly instead of referring to them by string
const refactoredParams = {
	nId: {
		type: "input",
		paramName: "nId",
		label: "Enter NodeID",
		helperText: "Last 4 HEX characters",
		formatValue: (value) => {
			return value
		},
		// TODO: Rename to `getError` and return a string or null. Refactor all usages.
		isValid: validateHex(4, 4),
	},
	newNId: {
		type: "input",
		paramName: "newNId",
		label: "Enter New NodeID",
		helperText: "Last 4 HEX characters",
		isValid: validateHex(4, 4),
	},
	flagBits: {
		type: FlagsSelector,
		paramName: "flagBits",
		label: "Flags",
		formatValue: (value) => {
			return BigInt(value).toString()
		},
		isValid: (value) => {
			const num = BigInt(value)
			return num !== null && num !== 0n
		},
		helperText: "",
	},
	rescuerNId: {
		type: "input",
		paramName: "rescuerNId",
		label: "Enter NodeID of Rescuer",
		helperText: "Last 4 HEX characters",
		isValid: validateHex(4, 4),
	},
	rescueeNId: {
		type: "input",
		paramName: "rescueeNId",
		label: "Enter NodeID to Rescue",
		helperText: "Last 4 HEX characters",
		isValid: validateHex(4, 4),
	},
	inputAngle: {
		type: "input",
		paramName: "angle",
		label: "Enter Angle",
		helperText: "(-) East Angle / (+) West Angle",
		formatValue: (value) => {
			return parseInt(value, 10)
		},
		isValid: (value) => {
			return !(isNaN(value) || Math.abs(value) > 60)
		},
	},
	roundNumber: {
		type: "input",
		paramName: "roundNumber",
		label: "Round Number",
		helperText: "Range from 1 to 8",
		formatValue: (value) => {
			return parseInt(value)
		},
		isValid: (value) => {
			let numValue = parseInt(value, 16)
			return !isNaN(numValue) && numValue >= 1 && numValue < 9
		},
	},
	panId: {
		type: "input",
		paramName: "panId",
		label: "PANID",
		helperText: "Enter HEX PANID w/o leading 0x",
		value: "1295",
		formatValue: parseHex,
		isValid: validateHex(4, 4),
	},
	channel: {
		type: "input",
		paramName: "channel",
		label: "Channel",
		helperText: "Enter HEX Channel w/o leading 0x",
		value: 10,
		formatValue: parseHex,
		isValid: validateHex(2, 4),
	},
	overnightOta: {
		type: "checkBox",
		paramName: "overnightOta",
		label: "Overnight OTA",
		value: 1,
	},
	autoRebroadcast: {
		type: "checkBox",
		paramName: "autoRebroadcast",
		label: "Auto rebroadcast",
	},
	autoUnicast: {
		type: "checkBox",
		paramName: "autoUnicast",
		label: "Auto Unicast",
	},
	allowOlderVersion: {
		type: "checkBox",
		paramName: "allowOlderVersion",
		label: "Allow Older Version",
	},
	weathersmartEnvelopeEastLimit: {
		type: "input",
		paramName: "eastLimit",
		label: "east limit",
	},
	weathersmartEnvelopeWestLimit: {
		type: "input",
		paramName: "westLimit",
		label: "west limit",
	},
	otaUpdateType: {
		type: NodeTypeSelector,
		paramName: "otaUpdateType",
	},
	daysBetweenConditioning: {
		type: "input",
		paramName: "daysBetweenConditioning",
		label: "Days Between Conditioning",
		helperText: "Range from 1 to 30",
		formatValue: (value) => {
			return parseInt(value)
		},
		isValid: (value) => {
			let numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 1 && numValue <= 30
		},
	},
	hailStowTrackingMinutes: {
		type: "input",
		paramName: "hailStowTrackingMinutes",
		label: "Tracking Time (in minutes)",
		helperText: "Range from 0 to 120 minutes",
		formatValue: (value) => {
			return parseInt(value)
		},
		isValid: (value) => {
			let numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 0 && numValue <= 120
		},
	},
	startHour: {
		type: "input",
		paramName: "startHour",
		label: "Hour to Start Conditioning",
		helperText: "Range from 6 to 18",
		formatValue: (value) => {
			return parseInt(value)
		},
		isValid: (value) => {
			let numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 6 && numValue <= 18
		},
	},
	minutes: {
		type: "input",
		paramName: "minutes",
		label: "Duration (Minutes)",
		helperText: "Range from 1 to 480",
		formatValue: (value) => {
			return parseInt(value)
		},
		isValid: (value) => {
			let numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 1 && numValue <= 480
		},
	},
	enable: {
		type: "checkBox",
		paramName: "enable",
		label: "Enable",
		value: 1,
	},
	disable: {
		type: "checkBox",
		paramName: "disable",
		label: "Disable",
		value: 0,
	},
	enable_battery_stow_mode: {
		type: "checkBox",
		paramName: "param1",
		label: "Enable Battery Stow Mode",
		value: 1,
	},
	battery_voltage_offset: {
		type: "input",
		paramName: "param2",
		label: "Battery Voltage Offset (in mVs)",
		helperText: "Range from -3000 to 3000",
		formatValue: (value) => {
			return parseInt(value)
		},
		isValid: (value) => {
			let numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= -3000 && numValue <= 3000
		},
		props: {
			style: {
				minWidth: "350px",
			},
		},
	},
	batteryVoltageThreshold: {
		type: "input",
		paramName: "batteryVoltageThreshold",
		label: "Battery Maintenance Threshold (in mV)",
		helperText: "Range from 15000mV and 24000mV ",
		formatValue: (value) => {
			return parseInt(value)
		},
		isValid: (value) => {
			let numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 15000 && numValue <= 24000
		},
		props: {
			style: {
				minWidth: "350px",
			},
		},
	},
	stow_temperature_hysteresis: {
		type: "input",
		paramName: "param3",
		label: "Stow Temperature Hysteresis (in degrees C)",
		helperText: "Range from 0 to 10",
		formatValue: (value) => {
			return parseInt(value)
		},
		isValid: (value) => {
			let numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 0 && numValue <= 10
		},
		props: {
			style: {
				minWidth: "350px",
			},
		},
	},
	windSpeed: {
		type: "input",
		paramName: "windSpeed",
		label: "Wind Speed (in MPH)",
		helperText: "Range from 0 to 150",
		formatValue: (value) => {
			return parseInt(value)
		},
		isValid: (value) => {
			let numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 0 && numValue <= 150
		},
	},
	duration: {
		type: "input",
		paramName: "duration",
		label: "Valid Duration of Wind Speed from Server (in minutes)",
		helperText: "Range from 15 to 720",
		formatValue: (value) => {
			return parseInt(value)
		},
		isValid: (value) => {
			let numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 15 && numValue <= 720
		},
	},
	chargingPanelLength: {
		type: "input",
		paramName: "chargingPanelLength",
		label: "Charging Panel length ",
		helperText: "Ran value is in inches scaled by 100",
		formatValue: (value) => {
			return parseInt(value) * 100
		},
		isValid: (value) => {
			const numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 0 && numValue <= 100
		},
	},
	rowSpacing: {
		type: "input",
		paramName: "rowSpacing",
		label: "Row Spacing",
		helperText: "Expected value in miles",
		formatValue: (value) => {
			return parseFloat(value)
		},
		isValid: (value) => !isNaN(value) && value > 0 && value < 1,
	},
	chargingEastTolerance: {
		type: "input",
		paramName: "chargingEastTolerance",
		label: "East Tolerance",
		helperText: "Tolerance Range 0-10",
		formatValue: (value) => {
			return parseInt(value) * 100
		},
		isValid: (value) => {
			const numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 0 && numValue <= 10
		},
	},
	chargingWestTolerance: {
		type: "input",
		paramName: "chargingWestTolerance",
		label: "West Tolerance",
		helperText: "Tolerance Range 0-10",
		formatValue: (value) => {
			return parseInt(value) * 100
		},
		isValid: (value) => {
			const numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 0 && numValue <= 10
		},
	},
	eastTolerance: {
		type: "input",
		paramName: "eastTolerance",
		label: "East Tolerance",
		helperText: "Please enter a value from 0 to 10",
		formatValue: (value) => {
			return parseInt(value) * 100
		},
		isValid: (value) => {
			const numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 0 && numValue <= 10
		},
		props: {
			style: {
				minWidth: "350px",
			},
		},
	},
	westTolerance: {
		type: "input",
		paramName: "westTolerance",
		label: "West Tolerance",
		helperText: "Please enter a value from 0 to 10",
		formatValue: (value) => {
			return parseInt(value) * 100
		},
		isValid: (value) => {
			const numValue = parseInt(value)
			return !isNaN(numValue) && numValue >= 0 && numValue <= 10
		},
		props: {
			style: {
				minWidth: "350px",
			},
		},
	},
}

const commandOptions = {
	[deviceTypes.P4Q]: [],
	[deviceTypes.GAMECHANGE_GEN2]: refactoredOptions,
	[deviceTypes.GAMECHANGE_GEN3]: refactoredOptions,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: refactoredOptions,
	[deviceTypes.GAMECHANGE_GEN4]: refactoredOptions,
}

export const getCommands = (userPrivileges, deviceType, deviceDetailsType) => {
	let commands = {}
	const commandLookup = {}
	const deviceTypeCommands = commandOptions[deviceType]

	deviceTypeCommands.forEach((command) => {
		if (accessAllowed(userPrivileges, command["privilege"])) {
			commandLookup[command["label"]] = command
			const category = command["category"]

			// Instantiate array if not done already
			if (!commands[category]) commands[category] = []

			// If we are controlling a node, we only want to show node-control commands.
			if (deviceDetailsType !== "node" || command.nodeControl) {
				commands[category].push(command["label"])
			}
		}
	})

	return {
		commands,
		commandLookup,
	}
}

export const commandParams = {
	[deviceTypes.P4Q]: {},
	[deviceTypes.GAMECHANGE_GEN2]: refactoredParams,
	[deviceTypes.GAMECHANGE_GEN3]: refactoredParams,
	[deviceTypes.GAMECHANGE_GEN3_DUAL]: refactoredParams,
	[deviceTypes.GAMECHANGE_GEN4]: refactoredParams,
}
