import TextField from "@material-ui/core/TextField"
import LabeledSelect from "../components/CustomMui/LabeledSelect"
import IntTable from "../components/InputFields/IntTable"

const windStowDefaultValues = [
	[0, -35, 21111, 23, 10],
	[1, -30, 21111, 24, 10],
	[2, -20, 21111, 25, 10],
	[3, -10, 21111, 26, 10],
	[4, 0, 21111, 27, 10],
	[5, 10, 21111, 28, 10],
	[6, 20, 21111, 29, 20],
	[7, 25, 21111, 30, 40],
	[8, 30, 21111, 31, 50],
	[9, 35, 45, 39, 60],
	[10, 23333, 23333, 63, 60],
	[11, 29999, 29999, 29999, 29999],
	[12, 29999, 29999, 29999, 29999],
]

const getWindStowError = (columnIndex, cell) => {
	const LEVEL_COLUMN = 0
	const TIME_COLUMN = 4
	switch (columnIndex) {
		case LEVEL_COLUMN:
			if (cell < 0 || cell > 12) {
				return "The level must be between 0 and 12"
			}
			break
		case TIME_COLUMN:
			if (cell < 0) {
				return "Time cannot be negative"
			}
			break
		default:
	}
}

/**
 * This is a list of all configurable settings (previously "SPA parameters") for a master. These settings are split into
 * different categories (the top level string keys). Each category defines a list of configurable settings. Each
 * setting must declare the following:
 *
 * id: The string ID of this command. This is the name of the setting as the IoT client will receive it.
 * internalValueTypeIdentifier: Which data type to convert the setting's value to before sending to the master. TODO: Make enum
 * elementType: The React element to use for this setting
 *
 * Each setting can declare the following:
 *
 * elementAttrs: An object to pass to the element as props
 * defaultValue: A default value
 * getError: A function that takes a value and returns an error text, or null if there are no errors.
 * show: Set to false to hide. Hidden settings must have a default value.
 */
const configurableSettings = Object.freeze({
	// TODO: Instead of lists, use objects with ids as keys
	// TODO: Refactor out the concept of internalValueTypeIdentifier if possible or use enum
	"SPA Parameters": [
		{
			id: "latitude",
			internalValueTypeIdentifier: "eFloat",
			elementType: TextField,
			elementAttrs: {
				label: "Latitude (N)",
				type: "number",
			},
		},
		{
			id: "longitude",
			internalValueTypeIdentifier: "eFloat",
			elementType: TextField,
			elementAttrs: {
				label: "Longitude (W)",
				type: "number",
			},
		},
		{
			id: "utcOffset",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "UTC Offset",
				type: "number",
			},
		},
		{
			id: "elevation",
			internalValueTypeIdentifier: "eFloat",
			elementType: TextField,
			elementAttrs: {
				label: "Elevation",
				type: "number",
			},
		},
		{
			id: "deltaUt1",
			internalValueTypeIdentifier: "eFloat",
			defaultValue: -0.1,
			elementType: TextField,
			show: false,
			elementAttrs: {
				label: "DeltaUt1",
				type: "number",
				disabled: true,
			},
		},
		{
			id: "deltaT",
			internalValueTypeIdentifier: "eFloat",
			defaultValue: 68.284,
			elementType: TextField,
			show: false,
			elementAttrs: {
				label: "DeltaT",
				type: "number",
				disabled: true,
			},
		},
		{
			id: "pressure",
			internalValueTypeIdentifier: "eFloat",
			elementType: TextField,
			elementAttrs: {
				label: "Pressure",
				type: "number",
			},
		},
		{
			id: "aveTemp",
			internalValueTypeIdentifier: "eFloat",
			elementType: TextField,
			elementAttrs: {
				label: "Temperature",
				type: "number",
			},
		},
		{
			id: "slope",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "Slope",
				type: "number",
			},
		},
		{
			id: "azmRotation",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "AzmRotation",
				type: "number",
			},
		},
		{
			id: "atmosRefraction",
			internalValueTypeIdentifier: "eFloat",
			elementType: TextField,
			elementAttrs: {
				label: "AtmosRefraction",
				type: "number",
			},
		},
		{
			id: "rowSpacing",
			internalValueTypeIdentifier: "eFloat",
			elementType: TextField,
			elementAttrs: {
				label: "Row Spacing",
				type: "number",
			},
		},
		{
			id: "panelLength",
			internalValueTypeIdentifier: "eFloat",
			elementType: TextField,
			elementAttrs: {
				label: "Panel Length",
				type: "number",
			},
		},
		{
			id: "planeInclinationAngle",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "Plane Inclination Angle",
				type: "number",
			},
		},
		{
			id: "slopeDirection",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "Slope Direction",
				type: "number",
			},
		},
	],
	"Tracking Parameters": [
		{
			id: "mechLimitWest",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "West Angle Limit",
				type: "number",
			},
		},
		{
			id: "mechLimitEast",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "East Angle Limit",
				type: "number",
			},
		},
		{
			id: "overnightAngle",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "Overnight Angle",
				type: "number",
			},
		},
		{
			id: "overnightMoveStartHour",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "Overnight Move Start Hour",
				type: "number",
			},
		},
		{
			id: "overnightMoveEndHour",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "Overnight Move End Hour",
				type: "number",
			},
		},
		{
			id: "strongestAngle",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "Strongest Angle",
				type: "number",
			},
		},
		{
			id: "snowStowAngle",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "Snow Stow Angle",
				type: "number",
			},
		},
		{
			id: "floodStowAngle",
			internalValueTypeIdentifier: "eInt",
			elementType: TextField,
			elementAttrs: {
				label: "Flood Stow Angle",
				type: "number",
			},
		},
	],
	"Call and Response": [
		{
			id: "fastPollingInterval",
			internalValueTypeIdentifier: "eFloat",
			defaultValue: 30,
			elementType: LabeledSelect,
			getError: (value) => {
				if (value === "" || value === undefined || value === null) return null
				const validValues = [5, 6, 7.5, 10, 15, 30, 45]
				if (!validValues.includes(value)) {
					return "Value must be one of the following: " + validValues.join(", ")
				}
			},
			elementAttrs: {
				label: "Fast Polling Interval",
				helperText: "Warning: Setting will have no effect if there are 350 nodes or more.",
				items: [
					[5, "5"],
					[6, "6"],
					[7.5, "7.5"],
					[10, "10"],
					[15, "15"],
					[30, "30"],
					[45, "45"],
				],
			},
		},
	],
	"Wind Stow Table": [
		{
			id: "windStowTable",
			internalValueTypeIdentifier: "array",
			elementType: IntTable,
			getError: (value) => {
				if (value === "" || value === undefined || value === null) return null
				for (let rowIndex = 0; rowIndex < value.length; rowIndex++) {
					if (value[rowIndex] && value[rowIndex].length > 0) {
						for (let columnIndex = 0; columnIndex < value[rowIndex].length; columnIndex++) {
							const error = getWindStowError(columnIndex, value[rowIndex][columnIndex])
							if (error) {
								return error
							}
						}
					}
				}
			},
			defaultValue: windStowDefaultValues,
			elementAttrs: {
				headers: ["Level", "East Angle", "West Angle", "Wind Speed", "Time to Restore"],
				errorCheck: getWindStowError,
			},
		},
	],
	"Optional Features": [
		{
			id: "nodeTemperatureCalibrationEnabled",
			internalValueTypeIdentifier: "eBool",
			defaultValue: true,
			elementType: LabeledSelect,
			getError: (value) => {
				if (value === undefined || value === null) {
					return "This field is required"
				}
			},
			elementAttrs: {
				label: "Enable Node Temperature Calibration",
				// NOTE: There is a bug in Mui causing values that evaluate to false (such as 0) to not show the label when
				//  the value is selected. See: https://github.com/mui-org/material-ui/pull/8587
				// This appears to have been merged into the now non-existent branch at
				// https://github.com/mui-org/material-ui/tree/v1-beta. It is, however, not part of the version used. The
				// maintainers have no intention of fixing this, so we'll just have to deal with it or change how "enabled" and
				// "disabled" are represented in the database, so that "disabled" is not falsy. In short:
				// If "Disabled" is selected, *nothing* will be displayed.
				// If "Enabled" is selected, "Enabled" will be displayed.
				items: [
					[true, "Enable"],
					[false, "Disable"],
				],
			},
		},
		{
			id: "nodeTemperatureCalibrationInterval",
			internalValueTypeIdentifier: "eInt",
			defaultValue: 5,
			elementType: TextField,
			getError: (value) => {
				if (value > 10 || value < 1) {
					return "1 to 10"
				}
			},
			elementAttrs: {
				label: "Temperature Calibration Interval (Days)",
				type: "number",
			},
		},
		{
			id: "antishadingEnabled",
			internalValueTypeIdentifier: "eInt",
			elementType: LabeledSelect,
			elementAttrs: {
				label: "Enable Anti-Shading",
				items: [
					[1, "Enable"],
					[0, "Disable"],
				],
			},
		},
	],
})

export default configurableSettings
